'use strict';

import { view } from '@/functions';

const prefix = '/partners';
export default [
    {
        path: `${prefix}`,
        name: 'partners',
        meta: {
            scopes: ['read_accounts', 'write_accounts']
        },
        component: () => view('partners/index'),
    },
];